<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <CInput v-model="dr_id" placeholder="DR ID"/>
        </CCol>
        <CCol lg="3">
          <Datepicker v-model="delivery_date_from" input-class="form-control" placeholder="Delivery date from" />
        </CCol>
        <CCol lg="3">
          <Datepicker v-model="delivery_date_to" input-class="form-control" placeholder="Delivery date to" />
        </CCol> 
        <CCol lg="1">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="3">
          <CInput v-model="department" placeholder="Department"/>
        </CCol>
       <CCol lg="3">
          <v-select 
            label="customer_name" 
            :options="customerList.data"
            :reduce="item => item.id"
            v-model="customer_id"
            placeholder="Customer"
          > 
          </v-select> 
        </CCol>
        <CCol lg="3">
          
        </CCol>
        <CCol lg="1">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block @click="clearSearch" color="info">Clear Search</CButton>
        </CCol>
      </CRow>
  </div>
</template>

<script>
  import config from '../../config.js';
  import axios from '../../axios';
  import vSelect from 'vue-select'
    import Datepicker from 'vuejs-datepicker';
  import 'vue-select/dist/vue-select.css';
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            customer_id:"",
            dr_id: "",
            delivery_date_from: "",
            delivery_date_to: "",
            department: "",
            customerList: {
				    data: []
			},
            
          }
        },
      mounted() { 
        this.getCustomer();
      },
      components: {vSelect, Datepicker},
      methods: {
        search() {
          const data = {
            customer_id: this.customer_id,
            dr_id: this.dr_id,
            delivery_date_from: this.delivery_date_from,
            delivery_date_to: this.delivery_date_to,
            department: this.department, 
          }
          this.$emit("depot-search-query", data)
        },
       

	     getCustomer(){

	    	axios.get(config.api_path+'/customer?page=1&limit=10000000')
	    	.then(response => {
	    		this.customerList = response.data; 
	    		this.customerList.data.unshift({
	    			customer_name: 'All',
	    			id: 'all'
	    		})
	    	})

	    },
        clearSearch() {
         this.customer_id ="",
          this.dr_id = "";
          this.delivery_date_from = "";
          this.delivery_date_to = "";
          this.department = ""; 
          const data = {
             customer_id: this.customer_id,
            dr_id: this.dr_id,
            delivery_date_from: this.delivery_date_from,
            delivery_date_to: this.delivery_date_to,
            department: this.department, 
          }
          this.$emit("depot-search-query", data)
        }, 
      }
    }
</script>

<style scoped>

</style>
